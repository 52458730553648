var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "accountMgrForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: {
              form: _vm.form,
              queryUrl: _vm.queryUrl,
              submitUrl: _vm.submitUrl,
              submitMethod: _vm.submitMethod,
              submitContentType: _vm.submitContentType,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return [
                    _vm.showReturn
                      ? _c("v-button", {
                          attrs: { text: "返回" },
                          on: { click: _vm.returnClick },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-block",
            { attrs: { title: "基本信息" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "姓名",
                    prop: "userName",
                    rules: [
                      {
                        required: true,
                        message: "请填写姓名",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      maxlength: 20,
                      placeholder: "真实姓名",
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userName", $$v)
                      },
                      expression: "form.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "手机号",
                    prop: "phone",
                    rules: [
                      {
                        required: true,
                        validator: _vm.validatePhone,
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "11位手机号码",
                      disabled: _vm.id && !_vm.editMobile,
                      maxlength: 11,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "登录名",
                    prop: "loginName",
                    rules: [
                      {
                        required: !_vm.loginNameDisabled,
                        message: "请填写登录名",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      disabled: _vm.loginNameDisabled,
                      placeholder: "3个以上字符",
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.loginName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "loginName", $$v)
                      },
                      expression: "form.loginName",
                    },
                  }),
                  _vm.id && _vm.form.loginName !== "ad_01"
                    ? _c("v-button", {
                        staticClass: "resetPwd",
                        attrs: {
                          permission: "resetPwd",
                          text: "重置密码",
                          type: "text",
                        },
                        on: { click: _vm.resetPwd },
                      })
                    : _vm._e(),
                  _vm.id && _vm.form.loginName !== "ad_01"
                    ? _c("v-button", {
                        staticClass: "resetPwd",
                        attrs: {
                          permission: "unlocked",
                          text: "账号解锁",
                          type: "text",
                        },
                        on: { click: _vm.unlockID },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.id
                ? _c(
                    "el-form-item",
                    { attrs: { label: "登录密码", required: "" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "pwd",
                          attrs: {
                            prop: "pwd",
                            rules: [
                              {
                                required: true,
                                validator: _vm.validatePass1,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              type: "password",
                              placeholder: "8到12个字符",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.pwd,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "pwd", $$v)
                              },
                              expression: "form.pwd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "pwd",
                          attrs: {
                            prop: "pwd2",
                            rules: [
                              {
                                required: true,
                                validator: _vm.validatePass,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              type: "password",
                              placeholder: "确认密码",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.pwd2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "pwd2", $$v)
                              },
                              expression: "form.pwd2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "职务" } },
                [
                  _c("v-input", {
                    attrs: { placeholder: "请输入职务信息", width: _vm.width },
                    model: {
                      value: _vm.form.duty,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "duty", $$v)
                      },
                      expression: "form.duty",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "角色权限" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色类型",
                    prop: "roleType",
                    rules: [
                      {
                        required: true,
                        message: "请选择角色类型",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.roleTypeOps,
                      disabled: !!_vm.defaultRoleType,
                      width: _vm.width,
                    },
                    on: { change: _vm.roleTypeChange },
                    model: {
                      value: _vm.form.roleType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleType", $$v)
                      },
                      expression: "form.roleType",
                    },
                  }),
                ],
                1
              ),
              _vm.form.roleType != "107"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "角色归属",
                            prop: "organizeId",
                            rules: [
                              {
                                required: true,
                                message: "请选择角色归属",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  subjoin: _vm.roleExtraParams,
                                  placeholder: "选择角色归属",
                                  disabled: !!_vm.defaultOrganizeId,
                                  width: _vm.width,
                                },
                                on: { onChange: _vm.organizeChange },
                                model: {
                                  value: _vm.form.organizeId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "organizeId", $$v)
                                  },
                                  expression: "form.organizeId",
                                },
                              },
                              "v-select2",
                              _vm.organizeParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属角色",
                            prop: "roleId",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属角色",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  subjoin: _vm.rolesExtraParams,
                                  placeholder: "请选择所属角色",
                                  disabled: !!_vm.defaultRoleId,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.roleId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "roleId", $$v)
                                  },
                                  expression: "form.roleId",
                                },
                              },
                              "v-select2",
                              _vm.roleParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.roleType === "107" ||
              (_vm.form.roleType === "101" && _vm.form.organizeId)
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属组织",
                            required: _vm.form.roleType === "107",
                            prop: "roleType",
                            rules: [
                              {
                                validator: _vm.validatorOrgId,
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("chosenListPanel", {
                            attrs: { list: _vm.orgList },
                            on: {
                              "update:list": function ($event) {
                                _vm.orgList = $event
                              },
                              select: _vm.toSelectOrg,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.oauthShow
                ? _c(
                    "el-form-item",
                    { attrs: { label: "授权物业服务", prop: "oauth" } },
                    [
                      _c(
                        "div",
                        { staticClass: "check-panel" },
                        _vm._l(_vm.oauthList, function (item, index) {
                          return _c("v-checkbox", {
                            key: index,
                            attrs: {
                              index: index,
                              type: "border",
                              label: item.name,
                            },
                            on: {
                              change: (val) => {
                                return _vm.authSelect(val, item, index)
                              },
                            },
                            model: {
                              value: item.checked,
                              callback: function ($$v) {
                                _vm.$set(item, "checked", $$v)
                              },
                              expression: "item.checked",
                            },
                          })
                        }),
                        1
                      ),
                      _c("div", { staticStyle: { color: "#b75151" } }, [
                        _vm._v(
                          "\n          注：授权服务之后，该用户如果具有园区管理app“服务工单”的权限，就可以查看上述选中服务的工单.\n        "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.oauthStoreShow
                ? _c(
                    "el-form-item",
                    { attrs: { label: "授权门店", prop: "oauth" } },
                    [
                      _c(
                        "div",
                        { staticClass: "check-panel" },
                        _vm._l(_vm.oauthStoreList, function (item, index) {
                          return _c("v-checkbox", {
                            key: index,
                            attrs: {
                              index: index,
                              type: "border",
                              label: item.name,
                            },
                            on: {
                              change: (val) => {
                                return _vm.authStoreSelect(val, item, index)
                              },
                            },
                            model: {
                              value: item.checked,
                              callback: function ($$v) {
                                _vm.$set(item, "checked", $$v)
                              },
                              expression: "item.checked",
                            },
                          })
                        }),
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "组织列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getOrgRelationListUrl,
          extraParams: _vm.extraParams,
          headers: _vm.orgTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.orgList,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.orgList = $event
          },
          "update:back-fill": function ($event) {
            _vm.orgList = $event
          },
          callback: _vm.onOrgSelect,
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "组织名称" },
                  model: {
                    value: _vm.searchParams.orgName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "orgName", $$v)
                    },
                    expression: "searchParams.orgName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "组织标签" },
                      model: {
                        value: _vm.searchParams.orgTagId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgTagId", $$v)
                        },
                        expression: "searchParams.orgTagId",
                      },
                    },
                    "v-select2",
                    _vm.orgTagParams,
                    false
                  )
                ),
                _vm.form.roleType === "107"
                  ? _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: {
                            subjoin: _vm.roleExtraParams,
                            label: "角色名称",
                          },
                          model: {
                            value: _vm.searchParams.roleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "roleId", $$v)
                            },
                            expression: "searchParams.roleId",
                          },
                        },
                        "v-select2",
                        _vm.roleParams,
                        false
                      )
                    )
                  : _vm._e(),
                _vm.form.roleType !== "101"
                  ? _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: { label: "关联项目" },
                          model: {
                            value: _vm.searchParams.communityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityId", $$v)
                            },
                            expression: "searchParams.communityId",
                          },
                        },
                        "v-select2",
                        _vm.communityParams,
                        false
                      )
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }