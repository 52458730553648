<template>
  <div class="accountMgrForm-container">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="submitUrl"
      :submitMethod="submitMethod"
      v-bind="submitConfig"
      :submitContentType="submitContentType"
      @update="update"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
    >
      <col2-block title="基本信息">
        <el-form-item
          label="姓名"
          prop="userName"
          :rules="[{ required: true, message: '请填写姓名', trigger: 'blur' }]"
        >
          <v-input
            v-model="form.userName"
            :maxlength="20"
            placeholder="真实姓名"
            :width="width"
          ></v-input>
        </el-form-item>
        <!-- <el-form-item label="新视窗用户">
          <v-select2  v-model="form.newseeUserId" :backText="newseeUserName" v-bind="newseeUserParams"  placeholder="请选择新视窗用户信息"></v-select2>
        </el-form-item> -->
        <el-form-item
          label="手机号"
          prop="phone"
          :rules="[
            { required: true, validator: validatePhone, trigger: 'blur' },
          ]"
        >
          <v-input
            v-model="form.phone"
            placeholder="11位手机号码"
            :disabled="id && !editMobile"
            :maxlength="11"
            :width="width"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="登录名"
          prop="loginName"
          :rules="[
            {
              required: !loginNameDisabled,
              message: '请填写登录名',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            v-model="form.loginName"
            :disabled="loginNameDisabled"
            placeholder="3个以上字符"
            :width="width"
          ></v-input>
          <v-button
            class="resetPwd"
            v-if="id && form.loginName !== 'ad_01'"
            permission="resetPwd"
            text="重置密码"
            type="text"
            @click="resetPwd"
          />
          <v-button
            class="resetPwd"
            v-if="id && form.loginName !== 'ad_01'"
            permission="unlocked"
            text="账号解锁"
            type="text"
            @click="unlockID"
          />
        </el-form-item>
        <el-form-item label="登录密码" required v-if="!id">
          <el-form-item
            class="pwd"
            prop="pwd"
            :rules="[
              { required: true, validator: validatePass1, trigger: 'blur' },
            ]"
          >
            <v-input
              v-model="form.pwd"
              type="password"
              placeholder="8到12个字符"
              :width="width"
            ></v-input>
          </el-form-item>
          <el-form-item
            class="pwd"
            prop="pwd2"
            :rules="[
              { required: true, validator: validatePass, trigger: 'blur' },
            ]"
          >
            <v-input
              v-model="form.pwd2"
              type="password"
              placeholder="确认密码"
              :width="width"
            ></v-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="职务">
          <v-input
            v-model="form.duty"
            placeholder="请输入职务信息"
            :width="width"
          ></v-input>
        </el-form-item>
      </col2-block>
      <col2-block title="角色权限">
        <el-form-item
          label="角色类型"
          prop="roleType"
          :rules="[
            { required: true, message: '请选择角色类型', trigger: 'change' },
          ]"
        >
          <v-select
            v-model="form.roleType"
            :options="roleTypeOps"
            :disabled="!!defaultRoleType"
            :width="width"
            @change="roleTypeChange"
          ></v-select>
        </el-form-item>
        <div v-if="form.roleType != '107'">
          <el-form-item
            label="角色归属"
            prop="organizeId"
            :rules="[
              { required: true, message: '请选择角色归属', trigger: 'change' },
            ]"
          >
            <v-select2
              v-model="form.organizeId"
              :subjoin="roleExtraParams"
              v-bind="organizeParams"
              placeholder="选择角色归属"
              :disabled="!!defaultOrganizeId"
              :width="width"
              @onChange="organizeChange"
            ></v-select2>
          </el-form-item>
          <el-form-item
            label="所属角色"
            prop="roleId"
            :rules="[
              { required: true, message: '请选择所属角色', trigger: 'change' },
            ]"
          >
            <v-select2
              v-model="form.roleId"
              :subjoin="rolesExtraParams"
              v-bind="roleParams"
              placeholder="请选择所属角色"
              :disabled="!!defaultRoleId"
              :width="width"
            ></v-select2>
          </el-form-item>
        </div>
        <!-- <el-form-item v-if="form.roleType === '101'" label="所属组织">
          <chosenListPanel :list.sync="orgList" @select="toSelectOrg"></chosenListPanel>
        </el-form-item> -->
        <div
          v-if="
            form.roleType === '107' ||
            (form.roleType === '101' && form.organizeId)
          "
        >
          <el-form-item
            label="所属组织"
            :required="form.roleType === '107'"
            prop="roleType"
            :rules="[{ validator: validatorOrgId, trigger: 'change' }]"
          >
            <chosenListPanel
              :list.sync="orgList"
              @select="toSelectOrg"
            ></chosenListPanel>
          </el-form-item>
        </div>
        <!-- <el-form-item label="授权功能">
          <v-checkbox v-model="form.desensitization" type="border" label="不脱敏导出"></v-checkbox>
        </el-form-item> -->
        <el-form-item v-if="oauthShow" label="授权物业服务" prop="oauth">
          <div class="check-panel">
            <v-checkbox
              v-model="item.checked"
              v-for="(item, index) in oauthList"
              :key="index"
              :index="index"
              type="border"
              :label="item.name"
              @change="
                (val) => {
                  return authSelect(val, item, index);
                }
              "
            />
          </div>
          <div style="color: #b75151">
            注：授权服务之后，该用户如果具有园区管理app“服务工单”的权限，就可以查看上述选中服务的工单.
          </div>
        </el-form-item>
        <el-form-item v-if="oauthStoreShow" label="授权门店" prop="oauth">
          <div class="check-panel">
            <v-checkbox
              v-model="item.checked"
              v-for="(item, index) in oauthStoreList"
              :key="index"
              :index="index"
              type="border"
              :label="item.name"
              @change="
                (val) => {
                  return authStoreSelect(val, item, index);
                }
              "
            />
          </div>
        </el-form-item>
      </col2-block>
      <template #saveBeforeSlot>
        <v-button v-if="showReturn" text="返回" @click="returnClick" />
      </template>
    </form-panel>
    <multi-select
      title="组织列表"
      :isShow.sync="isShow"
      :searchUrl="getOrgRelationListUrl"
      :extraParams="extraParams"
      :headers="orgTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="orgList"
      @callback="onOrgSelect"
    >
      <template #searchSlot>
        <v-input v-model="searchParams.orgName" label="组织名称"></v-input>
        <v-select2
          v-model="searchParams.orgTagId"
          v-bind="orgTagParams"
          label="组织标签"
        ></v-select2>
        <v-select2
          v-if="form.roleType === '107'"
          v-model="searchParams.roleId"
          :subjoin="roleExtraParams"
          v-bind="roleParams"
          label="角色名称"
        ></v-select2>
        <v-select2
          v-if="form.roleType !== '101'"
          v-model="searchParams.communityId"
          v-bind="communityParams"
          label="关联项目"
        ></v-select2>
      </template>
    </multi-select>
  </div>
</template>
<script>
import {
  getOrganizeURL,
  getRolesURL,
  getRoleTypeURL,
  addManageUserURL,
  updateManageUserURL,
  getUserInfoURL,
  getNewSeeUsersURL,
  resetPwgURL,
  getViewOauthURL,
  getStoreListURL,
  getOrgRelationListURL,
  getDropDownSearchTagURL,
  unlockedURL,
  updateTypeURL
} from './api'
import {
  Col2Block,
  ChosenListPanel,
  MultiSelect
} from '@/components/bussiness'
import { vCheckbox } from 'components/control/index'
import { mobileRegular } from 'common/regular'
import { communityParams } from 'common/select2Params'

export default {
  name: 'AccountMgrForm',
  components: {
    Col2Block,
    vCheckbox,
    ChosenListPanel,
    MultiSelect
  },
  props: {
    setBreadcrumb: {
      type: Boolean,
      default: true
    },
    showReturn: {
      type: Boolean,
      default: false
    },
    defaultId: String,
    defaultRoleType: String,
    defaultOrganizeId: String,
    defaultRoleId: String,
    isSystemAdded: Number
  },
  data () {
    return {
      width: 200,
      roleType: '',
      queryUrl: getUserInfoURL,
      id: this.$route.query.id,
      roleTypeOps: [],
      newseeUserName: '',
      communityParams,
      form: {
        id: '',
        userName: '',
        phone: '',
        loginName: undefined,
        pwd: '',
        pwd2: '',
        duty: '',
        roleType: undefined,
        roleId: '',
        oauth: '',
        storeIds: '',
        organizeId: '',
        headIcon: undefined,
        // newseeUserId: '',
        desensitization: false,
        roleIds: undefined,
        orgIds: undefined,
        isSystemAdded: this.isSystemAdded
      },
      organizeParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        }
      },
      roleParams: {
        searchUrl: getRolesURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        }
      },
      newseeUserParams: {
        searchUrl: getNewSeeUsersURL,
        request: {
          text: 'userName',
          value: 'roleId_'
        }
      },
      submitConfig: {
        // submitContentType: (this.$route.query.id || this.$route.query.tenantId) ? 'application/x-www-form-urlencoded;charset=UTF-8' : 'application/json;charset=UTF-8'
      },
      postHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      },
      orgList: [],
      getOrgRelationListUrl: getOrgRelationListURL,
      isShow: false,
      searchParams: {
        orgName: '',
        orgTagId: '',
        roleId: undefined,
        communityId: ''
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      orgTagParams: {
        searchUrl: getDropDownSearchTagURL,
        request: {
          text: 'tagName',
          value: 'tagId'
        },
        response: {
          text: 'tagName',
          value: 'id'
        }
      },
      oauthList: [],
      oauthIds: [],
      oauthStoreList: [],
      oauthStoreIds: [],
      isBack: false,
      loginName: undefined
    }
  },
  computed: {
    submitContentType () {
      return 'application/json;charset=UTF-8'
    },
    submitUrl () {
      return this.id ? updateManageUserURL : addManageUserURL
    },
    submitMethod () {
      return this.id ? 'PUT' : 'POST'
    },
    roleExtraParams () {
      return {
        roleType: this.form.roleType
      }
    },
    userInfo () {
      return this.$store.state.userInfo
    },
    rolesExtraParams () {
      let organizeId = ''
      if (this.userInfo.userType === '101') {
        organizeId = this.userInfo.orgId
      }
      return {
        roleType: this.form.roleType,
        organizeId: organizeId || this.form.organizeId
      }
    },
    loginNameDisabled () {
      let arr = ['100', '102', '101', '107']
      return (
        arr.indexOf(this.form.roleType) < 0 || (this.id && this.id.length > 0)
      )
    },
    extraParams () {
      let dataObj = { roleType: this.form.roleType }
      if (this.form.roleType === '101') {
        dataObj = {
          roleType: this.form.roleType,
          communityId: this.form.organizeId
        }
      }
      return dataObj
    },
    orgTableHeader () {
      let show = this.form.roleType === '107'
      return [
        {
          prop: 'orgName',
          label: '组织名称'
        },
        {
          prop: 'orgTagNames',
          label: '组织标签'
        },
        {
          prop: 'communityNames',
          label: '关联项目'
        },
        {
          prop: 'roleName',
          label: '角色名称',
          show: show
        }
      ]
    },
    orgShow () {
      let arr = ['101', '107']
      return arr.indexOf(this.form.roleType) > -1
    },
    oauthShow () {
      let arr = ['100', '101', '102']
      return arr.indexOf(this.form.roleType) > -1
    },
    oauthStoreShow () {
      let arr = ['101']
      return arr.indexOf(this.form.roleType) > -1
    },
    editMobile () {
      const permission = this.$store.getters['menuStore/getMenuPermission']('tenantOrganizeList')
      return permission && permission.editMobile
    },

    responseParams () {
      let responseParams1 = {
        id: 'orgId',
        name: 'orgName'
      }

      let responseParams2 = {
        id: 'id',
        name: ['orgName', 'roleName']
      }

      return this.form.roleType === '101' ? responseParams1 : responseParams2
    }
  },
  mounted () {
    this.getRoleType()
    if (this.defaultId) {
      this.id = this.defaultId
    }
    this.id && this.$refs.form.getData({ userId: this.id })
    if (this.setBreadcrumb) {
      this.id ? this.$setBreadcrumb('编辑') : this.$setBreadcrumb('新增')
    }
    if (this.id === undefined) {
      if (this.defaultRoleType) {
        this.form.roleType = this.defaultRoleType
      }
    }
  },
  watch: {
    defaultOrganizeId (newValue) {
      this.form.organizeId = newValue
    },
    defaultRoleId (newValue) {
      this.form.roleId = newValue
    }
  },
  methods: {
    update (data) {
      this.isBack = true
      this.form.id = data.id
      this.form.duty = data.duty
      this.form.loginName = data.loginName
      this.loginName = data.loginName
      this.form.phone = data.phone
      this.form.desensitization = !data.desensitization
      this.form.userName = data.userName
      this.newseeUserName = data.nsUserName
      this.form.organizeId = data.orgId
      this.form.roleId = data.roleId
      this.form.roleType = JSON.stringify(data.userType)
      this.roleType = JSON.stringify(data.userType)
      if (this.form.roleType === '107') {
        this.orgList =
          data.belongOrgList && data.belongOrgList.length > 0
            ? data.belongOrgList.map((item) => {
              return {
                text: item.orgRoleName,
                id: item.roleId,
                obj: item
              }
            })
            : []
      }
      if (this.form.roleType === '101') {
        this.orgList =
          data.belongOrgList && data.belongOrgList.length > 0
            ? data.belongOrgList.map((item) => {
              return {
                text: item.orgName,
                id: String(item.orgId),
                obj: item
              }
            })
            : []
      }

      this.$nextTick(() => {
        this.newseeUserName = ''
      })
    },
    getRoleType () {
      this.$axios.get(getRoleTypeURL).then((res) => {
        if (res.status === '100') {
          const { data } = res
          this.roleTypeOps = data.map((item) => {
            return {
              text: item.name,
              value: item.id
            }
          })
          !this.form.roleType && (this.form.roleType = data[0].id)
        }
      })
    },
    submitBefore (data) {
      let keyArr = Object.keys(data)
      keyArr.forEach((value) => {
        if (data[value] === undefined) {
          delete data[value]
        }
      })
      data.desensitization = data.desensitization ? 0 : 1
      if (!this.id) {
        // 新增
        data.mobileNum = data.phone
        delete data.phone

        data.adminDuty = data.duty
        delete data.duty

        data.password = data.pwd
        delete data.pwd
        delete data.pwd2

        data.userType = data.roleType
        delete data.roleType

        data.orgId = data.organizeId
        delete data.organizeId

        this.orgList.length > 0 &&
          (data.orgIds = this.orgList.map((item) => item.id))
        if (data.userType !== '101') {
          delete data.orgIds
        }
        this.oauthIds.length > 0 && (data.subIds = this.oauthIds)
        this.oauthStoreIds.length > 0 && (data.storeIds = this.oauthStoreIds)

        delete data.oauth
      } else {
        data.userId = data.id
        data.mobileNum = data.phone
        data.adminDuty = data.duty
        data.password = data.pwd
        data.userType = data.roleType
        data.orgId = data.organizeId

        // 编辑
        this.oauthIds.length > 0 && (data.subIds = this.oauthIds)
        this.orgList.length > 0 &&
          (data.orgIds = this.orgList.map((item) => item.id))
        this.oauthStoreIds.length > 0 && (data.storeIds = this.oauthStoreIds)

        if (data.userType !== '101') {
          delete data.orgIds
        }
      }

      data.logingName = data.loginName
      if (this.form.roleType === '107') {
        data.roleIds = this.orgList.map((item) => item.id)
        this.orgList[0].obj.orgId && (data.orgId = this.orgList[0].obj.orgId) // 组织账号默认
        data.roleId = data.roleIds[0]
      }

      // 添加操作对象参数 规则：账号名称-登录名
      let dataObject = `${data.userName}`
      if (data.loginName) {
        dataObject += `-${data.loginName}`
      }
      data.dataObject = dataObject

      return true
    },
    returnClick () {
      this.$emit('returnClick')
    },
    async submitSuccess (data, msg) {
      console.log(data, 'data-------------')
      if (this.id) {
        // 编辑
        this.openAllConfig(this.id)
        // msg && await this.$alert(msg)
      } else {
        // 新增
        this.openAllConfig(data)
        // 当角色权限不为区域、集团、项目时，id可能是账号名的提示
        data && !(/^(\d|\w|-)+$/.test(data)) && (await this.$alert(data))
        // data && (await this.$alert(data))
      }
      return true
    },
    // 开启邑生活物管端APP待办项设置,仅区域、项目、集团权限的角色
    async openAllConfig (id) {
      // arr中记录着区域、项目、集团对应的userType
      let arr = ['100', '101', '102']
      if (this.id) {
        if (arr.includes(this.form.roleType) && !arr.includes(this.roleType)) {
          // 如果修改后的角色权限符合要求,且原先的角色不符合要求,则调接口
          this.requestToAllConfig(id)
        }
      } else {
        if (arr.includes(this.form.roleType)) {
          this.requestToAllConfig(id)
        }
      }
    },
    // 开启邑生活物管端APP待办项设置的请求接口
    requestToAllConfig (id) {
      this.$axios.post(`${updateTypeURL}?userId=${id}`).then((res) => {
        if (res.status === 100) {
          console.log('成功了')
        }
      })
    },

    validatePass (rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value.length < 8 || value.length > 12) {
        callback(new Error('密码长度8到12个字符!'))
      } else if (value !== this.form.pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },
    validatePass1 (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入登录密码'))
      } else if (value.length < 8 || value.length > 12) {
        callback(new Error('密码长度8到12个字符!'))
      } else {
        callback()
      }
    },
    validatePhone (rule, value, callback) {
      if (value === '') {
        callback(new Error('请填写手机号'))
      } else if (!mobileRegular.test(value)) {
        callback(new Error('手机号不正确'))
      } else {
        callback()
      }
    },
    validatorOrgId (rule, value, callback) {
      console.log(this.orgList)
      if (this.form.roleType !== '107') {
        callback()
      } else if (this.orgList.length === 0) {
        callback(new Error('请选择所属组织'))
      } else {
        console.log(this.orgList.length)
        callback()
      }
    },
    resetPwd () {
      this.$axios
        .put(
          resetPwgURL,
          this.$qs.stringify({
            userId: this.id
          })
        )
        .then((res) => {
          if (res.status === 100) {
            this.$alert(res.data)
          }
        })
    },
    unlockID () {
      this.$axios
        .post(unlockedURL, {
          userId: this.id,
          userName: this.loginName
        })
        .then((res) => {
          if (Number(res.status) === 100) {
            this.$alert('账号解锁成功！')
          }
        })
    },
    roleTypeChange () {
      if (this.isBack) {
        this.isBack = false
        return false
      }
      this.orgList = []
      this.oauthList = []
      this.oauthIds = []
      this.oauthStoreList = []
      this.oauthStoreIds = []
      this.$refs.form.clearValidate()
    },
    toSelectOrg () {
      this.isShow = true
    },
    organizeChange (val) {
      if (this.oauthShow) {
        if (val && val.id) {
          this.getViewOauthList(val.id)
        } else {
          this.oauthList = []
          this.oauthIds = []
        }
      }
      if (this.oauthStoreShow) {
        if (val && val.id) {
          this.getViewOauthStoreList(val.id)
        } else {
          this.oauthStoreList = []
          this.oauthStoreIds = []
        }
      }
    },
    getViewOauthList (id) {
      let data = {
        organizeId: id,
        userType: this.form.roleType
      }
      this.id && (data.userId = this.id)
      this.$axios.post(getViewOauthURL, data).then((res) => {
        if (res.status === '100') {
          let oauthList = []
          let ids = []
          res.data &&
            res.data.forEach((item) => {
              if (item.selected || item.name === '集团报事') {
                ids.push(item.id)
              }
              oauthList.push({
                id: item.id,
                name: item.name,
                checked: item.name === "集团报事" ? true : item.selected == 1, // eslint-disable-line
              })
            })
          this.oauthList = oauthList
          this.oauthIds = ids
        }
      })
    },
    getViewOauthStoreList (id) {
      let data = {
        communityId: id,
        userType: this.form.roleType
      }
      this.id && (data.userId = this.id)
      this.$axios.get(getStoreListURL, { params: data }).then((res) => {
        if (res.status === 100) {
          let oauthList = []
          let ids = []
          res.data &&
            res.data.forEach((item) => {
              if (item.selected) {
                ids.push(item.id)
              }
              oauthList.push({
                id: item.id,
                name: item.name,
                checked: item.selected == 1, // eslint-disable-line
              })
            })
          this.oauthStoreList = oauthList
          this.oauthStoreIds = ids
        }
      })
    },
    authSelect (val, item, index) {
      if (val) {
        this.oauthIds.push(item.id)
      } else {
        let index = this.oauthIds.indexOf(item.id)
        this.oauthIds.splice(index, 1)
      }
    },
    authStoreSelect (val, item, index) {
      if (val) {
        this.oauthStoreIds.push(item.id)
      } else {
        let index = this.oauthStoreIds.indexOf(item.id)
        this.oauthStoreIds.splice(index, 1)
      }
    },
    onOrgSelect (data) {
      console.log(data)
    }
  }
}
</script>
<style lang="scss" scoped>
.accountMgrForm-container {
  height: 100%;
  .pwd {
    display: inline-block;
    margin-right: 10px;
  }
  .resetPwd {
    margin-left: 10px;
  }
  .check-panel {
    display: flex;
    flex-flow: wrap;
    padding: 10px;
    .checkbox-wrapper {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
</style>
<style>
.el-popup-parent--hidden /deep/.el-message-box__content {
  overflow: auto;
}
</style>
