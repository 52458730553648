// 获取列表
const getListURL = `${API_CONFIG.baseURL}serverUserAction!viewList.action`
// 导出列表
const exportListURL = `${API_CONFIG.baseURL}serverUserAction!exportToExcel.action`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 批量操作
const batchURL = `${API_CONFIG.butlerBaseURL}manager/change/status`
// 角色列表
const getOrganizeURL = `${API_CONFIG.baseURL}serverRoleAction!getOrganizeAccount.action`
// 角色归属列表
const getRolesURL = `${API_CONFIG.butlerBaseURL}sysRole/select2Roles`
// 获取角色类型
const getRoleTypeURL = `${API_CONFIG.baseURL}serverUserAction!getRoleType.action`
// 新增
const addManageUserURL = `${API_CONFIG.butlerBaseURL}manager/register`
// 更新
const updateManageUserURL = `${API_CONFIG.butlerBaseURL}manager/update`
// 获取详情
const getUserInfoURL = `${API_CONFIG.baseURL}serverUserAction!view.action`
// 新视窗用户
const getNewSeeUsersURL = `${API_CONFIG.baseURL}serverNewSeeAction!getNewSeeUsers.action`
// 重置密码
const resetPwgURL = `${API_CONFIG.butlerBaseURL}manager/resetPwd`
// 获取授权业务服务列表
const getViewOauthURL = `${API_CONFIG.baseURL}serverUserAction!viewOauth.action`
// 获取授权门店列表
const getStoreListURL = `${API_CONFIG.butlerBaseURL}user/auth/store`
// 获取组织列表
const getOrgRelationListURL = `${API_CONFIG.butlerBaseURL}sysRole/orgRelation`
// 获取组织标签列表
const getDropDownSearchTagURL = `${API_CONFIG.butlerBaseURL}orgTag/getDropDownSearchTag`
// 导入楼宇管理员
const importBuildingManagerURL = `${API_CONFIG.butlerBaseURL}emergency/importManager`
// 导入门岗
const importDoorerURL = `${API_CONFIG.butlerBaseURL}emergency/importGatekeeper`
// 账号解锁
const unlockedURL = `${API_CONFIG.baseURL}serverUserAction!unLock.action`
// 物管端App开启待办项配置
const updateTypeURL = `${API_CONFIG.butlerBaseURL}manager/user/notice/updateType`

const listInfo =`${API_CONFIG.butlerBaseURL}userCategory/listInfo`
const saveInfo =`${API_CONFIG.butlerBaseURL}userCategory/saveInfo`

const getCategoryByShopId = `${API_CONFIG.butlerBaseURL}storeProduct/getCategoryByShopId`
// 所属商家下拉搜索
const getShopIdsURL = `${API_CONFIG.baseURL}serverCodewordAction!getShops.action`
// 所属门店下拉搜索
const getStoreIdsURL = `${API_CONFIG.baseURL}orderAppriseAction!getStoreListByShopId.action`

export {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  getOrganizeURL,
  getRolesURL,
  getRoleTypeURL,
  addManageUserURL,
  updateManageUserURL,
  getUserInfoURL,
  getNewSeeUsersURL,
  resetPwgURL,
  getViewOauthURL,
  getStoreListURL,
  getOrgRelationListURL,
  getDropDownSearchTagURL,
  importBuildingManagerURL,
  importDoorerURL,
  unlockedURL,
  updateTypeURL,
  listInfo,
  saveInfo,
  getCategoryByShopId,
  getShopIdsURL,
  getStoreIdsURL
}
